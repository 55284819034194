<template>
	<div class="open-account-type">
		<div class="step-box">
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step01_h.png'"/>
				<p>填写资料</p>
			</div>
		
			<div class="step-item">
				<img :src="resourceURL + 'images/step_line_h.png'" alt="">
			</div>
		
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step02_h.png'"/>
				<p>身份验证</p>
			</div>
			<div class="step-item">
				<img :src="resourceURL + 'images/step_line.png'" alt="">
			</div>
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step03.png'"/>
				<p>提交审核</p>
			</div>			
		</div>

		<div class="item" @click="toBank(1)">
			<div class="image"><img :src="resourceURL + 'images/bank/icon_bank_1.png'" alt=""></div>
			<div class="info">国内银行账户</div>
		</div>

		<div class="item" @click="toBank(2)">
			<div class="image"><img :src="resourceURL + 'images/bank/icon_bank_2.png'" alt=""></div>
			<div class="info">海外银行账户</div>
		</div>

		<div class="item" @click="toBank(3)">
			<div class="image"><img :src="resourceURL + 'images/bank/icon_bank_3.png'" alt=""></div>
			<div class="info">USDT</div>
		</div>

		<div class="item" @click="toHuamei" style="margin-top:20px;height:28vw;">
			<div class="image"><img :src="resourceURL + 'images/bank/icon_huamei.png'" alt="" style="height:auto;"></div>
			<div class="info" style="height:28vw;line-height:28vw;">华美开户</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: 'account_bank',
		data() {
			return {

				token: "",
				
				showBank: false,

				resourceURL: this.$axios.defaults.resourceURL,
				
				bank_front_url: this.$axios.defaults.resourceURL + "images/icon_bank_add.png",
				
				disabledSubmit: true,
				
			}
		},
		mounted() {
			
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token
				
				this.init()
			}
		},
		methods: {
			init(){
				
				this.$axios({
					method: 'post',
					url: 'account_open/info',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						if (res.data.status == 2) {
							//	待审核
							this.$router.replace('/account/success')
						} else if (res.data.status == 3) {
							//	不通过
							this.$router.replace('/account/fail?token=' + this.token)
						}
												
					}else{
						this.$toast.fail(res.error_text)
					}
				})
				
			},
			
			toBank(bank_type){

				if(bank_type != 1 && bank_type != 2 && bank_type != 3){
					this.$toast.fail('请选择银行卡类型')
					return false
				}

				if(bank_type == 1){
					this.$router.push('/account/bank?token=' + this.token)
				}
				if (bank_type == 2) {
					this.$router.push('/account/hkbank?token=' + this.token)

				}
				if (bank_type == 3) {
					this.$router.push('/account/usdt?token=' + this.token)
				}
			},

			/*   
             * 跳转到华美
             */
			toHuamei(){
				var url = this.$axios.defaults.baseURL + 'info_term/huamei'
				console.log(url)
                if (window.JsObject != null) {
                    window.JsObject.redirectToHuamei(url);
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"redirectToHuamei": url});
                }
			},
		},
	}
</script>